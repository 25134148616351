<template>
  <div id="spec" class="container-fluid">
    <div class="row">
      <div class="col-12 mt-5 spec-title">
        <h1 style="font-weight: 300">NEW JUKE</h1>
        <h5>規格配備表</h5>
      </div>
    </div>
    <div class="row d-block d-lg-none">
      <div class="spec-img align-items-center justify-content-center d-flex">
        <!--<a target="_blank" @click="openPNG">
          <img class="spec-btn-download" :src="require(`@/static/spec-btn-download.png`)" alt="" srcset="">
        </a>-->
        <a target="_blank" @click="openPDF">
          <img class="spec-btn-download" :src="require(`@/static/spec-btn-download.png`)" alt="" srcset="">
        </a>
      </div>
    </div>

    <div class="row d-none d-lg-block mt-5">
      <div class="col-1"></div>
      <div class="col-10 px-3 spec-img-wrapper">
        <div class="spec-img">
          <img :src="require('@/static/spec.png')" alt="" srcset="">
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import spec from '@/assets/spec.js'
export default {
  name: "Spec",
  props: {
    msg: String
  },
  data() {
    return {
      spec_pdf: spec.pdf,
      spec_png: spec.png
    }
  },
  mounted() {
    // let file = require('@/static/spec.pdf')
    // console.log(file)
    // console.log(spec)
  },
  methods: {
    openPDF(){
      console.log('open')
      window.open('http://jukepresale.nissan.com.tw/assets/NEW_JUKE_規格配備表.pdf')
      // let pdfWindow = window.open("")
      // pdfWindow.document.write("<embed width='100%' height='100%' src='data:application/pdf;base64, " + encodeURI(this.spec_pdf) + "'/> <style> body,html{margin: 0} </style>")
    },
    openPNG(){
      // data:image/png;base64,
      let pdfWindow = window.open("")
      pdfWindow.document.write("<img width='100%' height='auto' src='data:image/png;base64, " + encodeURI(this.spec_png) + "'><img> <style> body,html{width: 100%, height: 100%; margin: 0; background-color: #0C1017;} </style>")
    }

  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
#spec {
  // min-height: 100vh;
  // background-color: #080e17;
  .spec-title {
    text-align: center;
    color: white;
    h1 {
      font-size: 2.5rem;
    }
  }
  .spec-btn-download{
    width: 60vw;
    min-width: 200px;
    &:hover{
      cursor: pointer;
    }
  }
}

.spec-table {
  margin-left: 50%;
  transform: translateX(-50%);
  width: 80vw !important;
  * {
    color: white !important;
  }
  #main-table tr,
  td {
    background-color: transparent !important;
    border: white 1px solid !important;
    padding: 5px !important;
  }
  #main-table {
    border: white 1px solid !important;
  }

  #main-table tr,
  td.no-border {
    border: none !important;
  }
}

.spec-img-wrapper{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 100%;
    margin-left: 10%;
  }
}
</style>

