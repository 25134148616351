<template lang="pug">
#app
  //#nav
    router-link(to='/main') Main
    router-link(to='/') Home
    router-link(to='/about') About
    router-link(to='/ticket') 取票
  //#nav
    router-link(to='/ticket') 取票
  router-view

</div>
</template>

<script>
export default {
    name: "App",
    components: {},
    mounted(){ 
      
      // console.log(scroll)
    }
};
</script>

<style lang="scss">
@import "@/assets/css/mixins.scss";

html,body{
  font-size: 17px;
  background-color: #080E17;
  
  @include for_breakpoint( mobile ){
    
  }

} 
body{
  overflow: hidden;
}

#app {
  letter-spacing: 0.1em;
  line-height: 2em;
  background-color: #080E17;
  h1,h2,h3,h4,h5,h6{

    line-height: 1.5em;
    font-weight: 700;
    letter-spacing: 0.12em;
  }
  @include rwd_sm{
    h1{
      font-size: min(5vw,3rem);
    }
    // h2{
    //   font-size: min(4.5vw,2rem);
    // }
  }

  // @include for_breakpoint( mobile ){
  //   h1{
  //     font-size: min(8vw,2rem);
  //   }
    
  // }
  p{

    line-height: 1.8em;
    opacity: 0.9;
  }
}


section{
  overflow: hidden;
  position: relative;
}
.container{
  max-width: 1440px;
}
</style>
