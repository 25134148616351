<template lang="pug">
section#playlist(  @mousemove="mousemoved" ref="section")
  .container
    .row(v-if="!isMobile")
      .col-12
        h1.text-center.pc-header 歌手亮相
        p.text-center.pc-header.text-center.intro.mb-4 承襲英國「原生不受限」的設計理念，挾歐系跑旅的運動肌理線條，<br>與質感跑格性能內裝，從細節就自帶獨特，在動靜間觸動人心，為你的潮流品味發聲。
    .row(v-if="isMobile")
      .col-12
        h3.text-center.mobile-header 歌手亮相
  .container-fluid
    DotBackground(v-if="!isMobile")
    .row.row-playlist
      .col-lg-4.col-md-5.col-sm-12.playlist-controller.d-flex.align-items-center.justify-content-center(data-scroll data-scroll-speed="2"  )
        .controller.d-flex.align-items-start.flex-column(v-if="!isMobile")
          .controller-wave.w-100.mt-5.mb-1
            SoundWave(height="200px")
          .controller-timeline.w-100.mb-3
            .play
              img(:src="playing?require(`@/static/icon-pause.png`):require(`@/static/icon-play.png`)" @click="playing = !playing")
            .timeline
              .value( :style='{ width: ( (valueRatio)/40*100)+ "%"}' )
          .controller-songs
            .song(v-for="(song,songId) in songs", 
                  @click="goToSlide(songId)",
                  :class="{active: currentSongId==songId}")
              .song-name - {{ song.name }}
        .contoller-mobile(v-if="isMobile && currentSong")
          .dots
            .dot(v-for="(song,songId) in songs", 
                  @click="goToSlide(songId)",
                  :class="{active: currentSongId==songId}")
          h6.animate__animated.animate__fadeIn(:key=" currentSong.title" v-html="currentSong.title")
          p(style="font-size: 16px").animate__animated.animate__fadeIn.animate_delay-1s(
            v-if="currentSong.content",
            :key="currentSong.content") {{currentSong.content}}
          .controller-timeline.w-100.mb-3
            .timeline
              .value( :style='{ width: ( (valueRatio)/40*100)+ "%"}' )
          .controls.mt-4
            .prev(@click="deltaSlide(-1)")
              img(:src="require('@/static/icon-backward.png')")
            .play
              img(:src="playing?require(`@/static/icon-pause.png`):require(`@/static/icon-play.png`)" @click="playing = !playing")
            .next(@click="deltaSlide(1)")
              img(:src="require('@/static/icon-forward.png')")


      .col-lg-8.col-md-7.col-sm-12.playlist-album.d-flex.align-items-center.justify-content-center
        carousel-3d(:height="650" :width="650" :autoplay="false" :autoplayTimeout="5000" ref="songcarousel" :controls-visible="true"
                    @before-slide-change="onBeforeSlideChange")
          slide(v-for="(song, index) in songs" :key="index" :index="index" :loop="true" :style="getStyle(song)")
            .icon-zoom(@click="$emit('openShowSongModal',currentSong)")
              i.fas.fa-search-plus
            .slide-content(v-if="!isMobile")
              h1.slide-title(v-html="song.title")
              p.slide-desc {{ song.content }}
      // :class='{"double-lh": index===3 || index===5}'
</template>

<script>
import { Carousel3d, Slide } from 'vue-carousel-3d';
import DotBackground from './DotBackground'
import SoundWave from './SoundWave'
import { mapState } from 'vuex';

export default {
  name: "PlayList",
  props: {
  },
  components:{
    Carousel3d, Slide,DotBackground,SoundWave
  },
  mounted() {
    this.interval = setInterval(()=>{
      if (this.playing){

        this.valueRatio+=1
        if (this.valueRatio>40){
          this.deltaSlide(1)
          this.valueRatio=0
        }
      }
    },115)
  },
  beforeDestroy(){
    clearInterval(this.interval)
  },
  watch:{
    currentSongId(){
      this.valueRatio=-1
    }
  },
  computed: {
    ...mapState(['isMobile']),
    currentSong(){
      return this.songs[this.currentSongId]
    }
  },
  methods: {
    deltaSlide(delta){
      this.$refs.songcarousel.goSlide( (this.currentSongId+delta+this.songs.length)% this.songs.length )
      // console.log(delta)
    },
    
    onBeforeSlideChange(index) {
      this.currentSongId = index
      // console.log('@onBeforeSlideChange Callback Triggered', 'Slide Index ' + index)
    },
    setLocale() {
      this.$i18n.locale = this.$i18n.locale == "zh" ? "en" : "zh";
    },
    getStyle(song) {
      let bkg_img = require(`@/static/${song.image}`);

      let style = `
        background: url("${bkg_img}");
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: auto 100%;
      `;
      return style;
    },
    mousemoved(evt){
      // console.log(evt)
      // if (evt.target == this.$refs.section){

      var rect =  this.$refs.section.getBoundingClientRect();
      var x = evt.clientX - rect.left; //x position within the element.
      var y = evt.clientY - rect.top;  //y position within the element.
      window.mousePos = {x,y}

      // }
      // console.log(evt)
    },
    goToSlide(index) {
      this.$refs.songcarousel.goSlide(index)
    }
  },
  data() {
    return {
      interval: -1,
      playing: true,
      currentSongId: 1,
      valueRatio: 0,
      songs: [
        {
          name: "就想light著你",
          image: "highlight-1.jpg",
          title: "LED Y字型魅力頭燈",
          content: "全LED燈具，提升光照範圍，光感更接近自然光。"
        },
        {
          name: "累的時候靠著我",
          image: "highlight-2.jpg",
          title: "頂級Alcantara®麂皮內裝<br>Monoform跑格化座椅",
          content: "超跑等級內裝質料，輕量化設計，提升質感。"
        },
        {
          name: "當你被圈粉的時候",
          image: "highlight-3.jpg",
          title: "19吋雙色切削式鋁圈",
          content: "同級唯一配備，將跑格動感靈魂極大化。"
        },
        {
          name: "速度是會上癮的",
          image: "highlight-4.jpg",
          title: "1.0 Turbo引擎 <div class='title-sm' style='font-size: 1.8vmin; display: inline'>最大扭力20.4kgm(超增壓模式)</div><br>DCT雙離合器 <div class='title-sm' style='font-size: 1.8vmin; display: inline'>油耗17.6KM/L</div>",
          content: "油耗與絶佳操控的駕馭表現，充分感受熱血澎湃的操駕體驗。"
        },
        {
          name: "在你左右唱情歌",
          image: "highlight-5.jpg",
          title: "BOSE® PERSONAL® PLUS<br>環繞音響系統",
          content: "零死角還原音場效果，一場身歷其境的聽覺饗宴聲動您的駕馭感官。"
        },
        {
          name: "硬底子沒在怕",
          image: "highlight-6.jpg",
          title: "UHSS超高剛性車身 1,500Mpa",
          content: "榮獲歐盟與澳洲新車安全評鑑最高五星安全評價。"
        },
        {
          name: "只有我能給的安全感",
          image: "highlight-7.jpg",
          title: "NIM全方位智行科技<br>P-IEB行人/自行車防追撞緊急煞車系統",
          content: "隨時為你預測並及時制止潛在危機，安心享受駕馭樂趣。"
        },
        {
          name: "日不落色號",
          image: "highlight-8.jpg",
          title: "潮流車色&nbsp&nbsp",
          content: "不受限的多樣車色，滿足每位車主與眾不同的需求。"
        }
      ]
    };
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="sass">
@import "@/assets/css/mixins.scss"
#playlist
  min-height: 100vh
  // height: 100vh
  padding-top: 10vh
  padding-bottom: 10vh

  .intro
    font-size: 1.2rem
    line-height: 1.8
    letter-spacing: 0.1em
    +rwd_sm
      font-size: 1rem


  .mobile-header
    color: white

  .pc-header
    color: white
  .row-playlist
    +rwd_sm
      display: flex
      .playlist-controller
        order: 5
        top: -100px
  .playlist-controller
    height: 100%
    z-index: 1
    .sound-wave
      width: 100%
      height: 300px
    .play
      width: 20%
        // color: black
        
    .controller-timeline
      display: flex
      align-items: center
      justify-content: center
      .play
        width: 12%
        padding-bottom: 7px
        padding-left: 5px
        img
          height: 24px
        &:hover
          cursor: pointer
    .timeline
      width: 90%
      height: 6px
      background-color: rgba(white,0.2)
      border-radius: 10px
      position: relative
      overflow: hidden
      margin-right: 10px
      @keyframes valuePlay
        0%
          width: 0%
        100%
          width: 100%
      .value
        content: ""
        background: linear-gradient(45deg,#AF60FE,#FF4773)
        // width: 30%
        transition: 0.15s
        width: 0%
        height: 100%
        border-radius: 10px
        // animation: valuePlay 5s linear 2s infinite
            

    .controller
      position: relative
      background-image: url('~@/static/playlist-bkg-controller.png')
      background-size: 100% 100%

      background-repeat: no-repeat
      color: white
      width: 80%
      height: 65%
      padding: 0 20px
      padding-bottom: 30px
      // border: solid 1px white
      &::before
        content: ''
        position: absolute
        background-image: url('~@/static/playlist-bkg-black-controller.png')
        background-size: 120% 100%
        // background-position: 0% 
        width: 120%
        height: 110%
        left: -60px
        top: -10px
        z-index: -1
      .controller-wave
        // background-color: #000
      .controller-songs
        line-height: 2
        width: 100%
        .song
          padding: 2px 5px 
          padding-left: 20px
          transition: 1s
          font-weight: 500
          display: block
          cursor: pointer
          background: linear-gradient(45deg, #000 40%, #AF60FE 50% ,#AF60FE,#FF4773 90%)
          background-size: 400% auto
          &:hover
            transition: 0.5s
            background-position: 40% 0
            background-image: linear-gradient(45deg, rgba(0,0,0,0.5) 35%, rgba(175, 96, 254, 0.5) 40% ,rgba(255, 71, 115, 0.5) 70% , rgba(175, 96, 254, 0.5) 90%)
          &.active
            background-position: 80% 0
            // background: linear-gradient(45deg,#AF60FE,#FF4773)

    .contoller-mobile
      color: white
      padding-top: 30px
      width: 100%
      padding-left: 20px
      padding-right: 20px
      position: relative
      
      h3
        font-size: 1.5rem
        mih-height: 3em
      p
        min-height: 4em
      .dots
        display: flex
        align-items: center
        justify-content: center
        .dot
          width: 12px
          height: 12px
          cursor: pointer
          background-color: #fff
          display: inline-block
          border-radius: 50%
          margin: 0px 10px
          margin-bottom: 15px
          &.active
            background: linear-gradient(45deg, #AF60FE 0% ,#FF4773 100%)

      .controls
        display: flex
        justify-content: space-around
        align-items: center
        font-size: 3rem
      .prev,.next
        cursor: pointer
        img
          width: 15vw
        &:hover
          transform: scale(1.2)
        &:active
          transform: scale(1.1)
          
      .play
        width: 100px
        height: 100px
        border: solid 3px white
        border-radius: 50%
        display: flex
        justify-content: center
        align-items: center
        cursor: pointer
        &:hover img
          transform: scale(1.2)

  .playlist-album
    height: 100%
    min-height: 500px
    +rwd_md
      min-height: 50vh
    .carousel-3d-container
      overflow: initial
      .carousel-3d-slider
        .carousel-3d-slide
          transition: 0.5s
          overflow: initial 
          background-size: 100% auto
          &:before
            content: ''
            position: absolute
            width: 102%
            height: 102%
            background-color: black
            transition: 0.5s 0.5s
            top: -1%
            left: -1%
            opacity: 0.9
          .icon-zoom
            position: absolute
            left: 20px
            top: 20px
            color: white
            font-size: 1.5rem
            cursor: pointer
            opacity: 1
            transition: 0.5s
            &:hover
              transform: scale(1.2)
          .slide-content
            position: absolute
            bottom: 0
            left: 0
            padding: 10px 20px
            transition: 0.5s 0.1s
            opacity: 1
            letter-spacing: 2px
            .slide-title
              position: relative
              width: fit-content
              font-size: 1.5rem
              color: black
              transform: translateX(-10px)
              width: fit-content
              height: auto
              transition-delay: 0.5s
              // .title-sm
              //   display: none !important
              //   font-size: 18px !important
              &::before
                content: ''
                width: 120%
                height: 100%
                position: absolute
                background-color: white
                transform: skewX(20deg)
                // top: -15px
                transition: 0.5s
                left: -25px
                // border-bottom: 60px solid white
                // border-right:  30px solid transparent
                z-index: -1
              &::after
                content: ''
                width: 80%
                height: 100%
                position: absolute
                background-color: white
                left: -40px
                top: 0
                z-index: -1

            .slide-desc
              margin-top: 10px
              margin-bottom: 0
              transform: translateX(-10px)
              color: white
              text-shadow: 0px 0px 10px rgba(black,0.5)

        .current
          &:before
            transition: 0.5s
            opacity: 0

        .right-1, .left-1
          // filter: brightness(40%) !important
          // opacity: 1 !important
          .icon-zoom
            opacity: 0
          .slide-content
            opacity: 0
            transform: translateY(50px)
          &:before
            transition: 0.5s
            opacity: 0.5

        .right-2, .left-2
          // filter: brightness(10%) !important
          // display: none
          opacity: 0.3 !important
          filter: blur(10px)  brightness(5%) 
          .icon-zoom
            opacity: 0
          .slide-content
            opacity: 0
            transform: translateY(10px)
          &:before
            opacity: 0.9
</style>
