<template lang="pug">
section#intro
  .container-fluid
    .row.intro-images
      .col-12
        img.img-car(data-scroll  data-scroll-speed="2"
                    data-scroll-direction="horizontal"  
                   :src="require(`@/static/intro_car.png`)")
        img.img-mic(data-scroll data-scroll-speed="-5" 
                    data-scroll-direction="horizontal"  
                    :src="require(`@/static/intro_mic.png`)")
    .row
      .col-12.intro-logo.mt-4.ml-1
        a(href="https://new.nissan.com.tw/nissan/?utm_source=2020_new_juke" target="_blank")
          img.img-logo(:src="require(`@/static/intro_logo.png`)")
      // .col-12.intro-logo-min.mt-3
        img.img-logo(:src="require(`@/static/intro_logo_min.svg`)")
    .row
      .col-md-6
      .col-md-4.intro-slogan.d-flex.flex-column.align-items-center.mt-3(data-scroll-section)
        img.img-slogan(data-scroll-speed="2"  :src="require(`@/static/intro_slogan.png`)")
        img.img-btn(data-scroll-speed="2" :src="require(`@/static/intro_btn.png`)")
    .row.cta-seat-select(@click="$emit('scrollTo','#seat')" data-scroll data-scroll-speed="2" v-if="!$store.state.displayLive")
      .col-12
        span 立即搶票<br>
        span.icon-down
          i.fa.fa-angle-down
    .row.cta-seat-select(@click="$emit('scrollTo','#live')" data-scroll data-scroll-speed="2" v-if="$store.state.displayLive")
      .col-12
        span 直播首發<br>
        span.icon-down
          i.fa.fa-angle-down
    SoundWave(height="250px" scaleX="0.25")

</template>
<script>
import SoundWave from './SoundWave'
export default {
  name: "Intro",
  props: {
    msg: String
  },
  mounted(){

    // setTimeout(()=>{
    // },500)
  },
  methods: {
    setLocale() {
      this.$i18n.locale = this.$i18n.locale == "zh" ? "en" : "zh";
    }
  },
  computed: {},
  components: {
    SoundWave
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="sass">

@import "@/assets/css/mixins"
#intro
  overflow: initial
  +rwd_sm
    overflow-x: hidden
    overflow-y: initial
  position: relative
  height: 100vh
  width: 100%

  .sound-wave
    position: absolute
    bottom: -100px
    width: 100vw
    z-index: 1
  +rwd_sm
    height: 75vh
  +rwd_md
    height: 90vh
  +rwd_xs
    height: 75vh
    .sound-wave
      width: 100vw
      overflow-x: hidden
    canvas
      max-width: 100vw
      transform: translate3D(50%, 50%, 0) scale(2)

  .container-fluid
    height: 100%
    min-height: 100%
    width: 100%
    background-color: black
    background-image: url('~@/static/intro_bkg.png')
    background-size: cover
    background-repeat: no-repeat
    background-position: 30% 50%
    overflow: hidden
    +rwd_md
      // min-height: 100vh
      background-size: auto 115%
      background-position: 30% 170%
    +rwd_sm
      // min-height: 100vh
      background-size: auto 120%
      background-position: 30% 170%
      &:before
        display: none !important
    &:before
      content: ""
      display: block
      position: absolute
      background:  linear-gradient(180deg,#080E17 40% , transparent 100%)
      left: 0
      top: 0 
      width: 30%
      height: 35%
    &:after
      content: ""
      display: block
      position: absolute
      background:  linear-gradient(0deg,#080E17 40% , transparent 100%)
      left: 0
      bottom: 0 
      width: 100%
      height: 20%
      +rwd_sm
        &:after
          z-index: -1
        // display: none


  .intro-images
    width: 100%
    height: 100%
    position: absolute
    top: 0
    left: 0
    overflow: initial
    .img-car
      position: absolute
      width: 85vmin
      max-width: 1000px
      bottom: -20%
      left: 10vw
      // z-index: 
    .img-mic
      position: absolute
      width: 85vmin
      max-width: min(700px, 50vw)
      bottom: 0
      // right: -15vh
      left: 85vw
    +rwd_xl
      .img-car
        top: initial
        bottom: -24vh
        left: 10vw
        width: 88vmin
      .img-mic
        width: 65vmin
        bottom: 10vh
        left: initial
        right: -10vw 
    +rwd_lg
      .img-car
        width: 100vmin
        // display: none
        top: initial
        bottom: -30vh
        left: 10vw
      .img-mic
        width: 60vmin
        bottom: 5vh
        left: initial
        right: -30vw
    +rwd_md
      .img-car
        width: 90vmin
        top: initial
        bottom: -20vh
        left: 0vw
      .img-mic
        width: 100vmin
        // max-width: min(800px, 80vw)
        bottom: 10vh
        left: initial
        right: -30vw 
    +rwd_sm
      .img-car
        top: initial
        bottom: 0vh
        left: 0vw
      .img-mic
        width: 100vmin
        // max-width: min(800px, 80vw)
        bottom: 15vh
        left: initial
        right: max(-35vw, -200px)
    +rwd_xs
      .img-car
        top: initial
        bottom: 0vh
        left: 0vw
      .img-mic
        width: 100vmin
        // max-width: min(800px, 80vw)
        bottom: 15vh
        left: initial
        right: max(-35vw, -200px)



  .intro-logo
    width: 100%
    +rwd_sm
      display: none !important
      height: 50px
  .intro-slogan
    +rwd_sm
      padding: 0
    img.img-slogan
      width: 120%
      max-width: 90vw
    img.img-btn
      width: 70%
      max-width: 100vw
      filter: drop-shadow(8px 8px 0px #fff)
    +rwd_sm
      position: absolute
      bottom: 0
      z-index: 5
  .cta-seat-select
    z-index: 5
    font-size: 1rem
    letter-spacing: 0.1em
    color: #FFF
    width: 100%
    text-align: center
    position: absolute
    bottom: 100px
    cursor: pointer
    transition: 0.5s
    span 
      transition: opacity 0.5s
      opacity: 0.8
      position: relative
    &:hover
      bottom: 120px
      span 
        opacity: 0.9
    +rwd_sm
      display: none

h3
  margin: 40px 0 0
ul
  list-style-type: none
  padding: 0
li
  display: inline-block
  margin: 0 10px
a
  color: #42b983

.icon-down
  animation: icon-move 1s infinite linear

@keyframes icon-move
  0%, 100%
    bottom: 0%
  50%
    bottom: 10px

</style>
