<template lang="pug">
.home(data-scroll-container)
  a(@click="openReserve")
    .reservation 聲動試乘 ►
  Nav(@scrollTo="scrollTo" @updateScroll="updateScroll")
  Intro(
    data-scroll-section,
    :scrollControl="scrollControl",
    @scrollTo="scrollTo"
  )
  Live(data-scroll-section v-show="$store.state.displayLive")
  SeatSelection(
    v-show="!$store.state.displayLive"
    data-scroll-section,
    @toggleShowModal="() => (showModal = true)"
  )
  Teaser(data-scroll-section @openShowVideoModal="openShowVideoModal" )
  PlayList(data-scroll-section, @openShowSongModal="openShowSongModal")
  Discount(data-scroll-section)
  Spec(data-scroll-section)
  Footer(data-scroll-section)
  div(style="max-height: 0px")
    fullPage.fullpage-cert(
      :show="showModal",
      :class="{ 'justify-start': showModal }",
      @closeFullpage="showModal = false"
    )
      .container
        .col-12
          p(v-html="modalTitle + modalContent")

    fullPage.slideDetail(:show="showSong", @closeFullpage="showSong = false")
      .img-wrapper(:style="getSlideImgStyle(song)")
        // img(:src="require(`@/static/${song.image}`)", v-if="song.image")
      .slide-content
        h1.slide-title(v-html="song.title")
        p.slide-desc {{ song.content }}
    fullPage.slideDetail.slideVideo(:show="showVideo", @closeFullpage="showVideo = false")
      iframe.iframe-video(:src="vidSrc" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen)
      // video-player(
        :options="playerOptions",
        :playsinline="false"
        )
    fullPage.slideDetail(:show="showIframe", @closeFullpage="showIframe = false")
      iframe.iframe-form(src="https://service.nissan.com.tw/testdrive/juke")
</template>

<script>
// @ is an alias to /src
import Nav from "@/components/Nav.vue";
import Intro from "@/components/Intro.vue";
import Live from "@/components/Live.vue";
import SeatSelection from "@/components/SeatSelection.vue";
import Teaser from "@/components/Teaser.vue";
import PlayList from "@/components/PlayList.vue";
import Discount from "@/components/Discount.vue";
import Spec from "@/components/Spec.vue";
import Footer from "@/components/Footer.vue";
import LocomotiveScroll from "locomotive-scroll";

import fullPage from "@/components/fullPage";
import "video.js/dist/video-js.css";
import { videoPlayer } from "vue-video-player";

export default {
  name: "Home",
  components: {
    Nav,
    Intro,
    Live,
    SeatSelection,
    Teaser,
    PlayList,
    Discount,
    Spec,
    Footer,
    fullPage,
    videoPlayer
  },
  mounted() {
    setTimeout(() => {
      this.scrollControl = new LocomotiveScroll({
        el: document.querySelector("[data-scroll-container]"),
        smooth: true,
        smoothMobile: true
      });
      this.scrollControl.on("scroll", evt => {
        // console.log(evt.scroll.y)
        this.$store.commit("setScrollY", evt.scroll.y);
      });
    }, 500);
  },
  data() {
    return {
      vidSrc:
        "https://www.youtube.com/embed/_NUoXioxhHM?rel=0&playlist=_NUoXioxhHM",
      showVideo: false,
      scrollControl: null,
      showModal: false,
      showSong: false,
      showIframe: false,
      song: {
        image: "highlight-3.jpg"
      },
      playerOptions: {
        muted: false,
        language: "zh",
        autoplay: true,
        sources: [
          {
            type: "video/mp4",
            src: window.domain + "/assets/vid1.mp4"
          }
        ]
      },
      modalTitle:
        window.innerWidth > 500
          ? `<h3>NEW JUKE FB抽獎辦法</h3>`
          : `<h3>NEW JUKE<br>FB抽獎辦法</h3>`,
      modalContent:
        `
      【主辦單位】
      裕隆日產汽車股份有限公司
      【活動期間】
      109/11/06 00:00 ～ 109/11/18/ 23:59，以FB系統內時間為準。
      【活動內容】
      凡是完成活動步驟的網友們，NISSAN將從參加活動的網友中抽出53位幸運兒贈送BOSE耳機乙副或NISSAN不倒隨行杯乙個。
      一個臉書帳號只獲乙次抽獎之機會。每人僅限中獎一次，恕不重複給獎，以姓名、手機號碼、E-mail作為識別依據。如有發現有重複中獎之情況，或冒用他人姓名參加活動，將取消中獎資格。
      【活動步驟】
      < 步驟一 >
      於活動期間內，點選座位圖，開啟NISSAN官方臉書聊天機器人，參與對話並獲得電子票券。
      < 步驟二 >
      將電子票券分享到自己的塗鴉牆設為公開，並且hashtag #NEWJUKE聲動你的駕馭感官。
      < 步驟三 >
      回到NISSAN官方臉書聊天機器人對話並點選「我同意上述活動辦法」以填寫個資。
      【活動資格】
      活動期間於FB上完成指定之步驟，缺一不可。
      【獎品內容】
      頭獎：BOSE 700 無線消噪耳機1副 (市價新臺幣13,000元整) (1名)
      貳獎：BOSE SoundSport無線耳機1副 (市價新臺幣4,900元整) (2名)
      參奬：NISSAN 不倒隨行杯1個 (市價新臺幣450元整) (50名)
      一個臉書帳號只獲乙次抽獎之機會。每人僅限中獎一次，恕不重複給獎，以姓名、手機號碼、E-mail作為識別依據。如有發現有重複中獎之情況，或冒用他人姓名參加活動，將取消中獎資格。
      【中獎名單公佈】
      中獎名單將於第三人見證下採電腦隨機抽出，活動小組會於109/11/24(二) 20:00前公佈得獎者於NISSAN 官方網站及FB粉絲團活動貼文留言處，請參加者密切注意NISSAN 官方粉絲團公告。得獎者須於109/12/1(二)前私訊FB官方粉絲團，以利後續寄發中獎通知信通知中獎訊息，得獎者於收到中奬通知信，若七日內(以郵戳為憑)未將領獎憑證正本寄回確認得獎資格，或所寄回之資料經審核資格不符者或提供之領獎資料與參加時填寫不相同者，將視同放棄且喪失中獎資格，主辦單位亦不再替補。
      【注意事項】`
          .split("\n")
          .map(line => `<p>${line}</p>`)
          .join("") +
        `<ul>` +
        `* 活動獎品以實物為準，且獎品之顏色、規格及配件等皆由主辦單位指定，恕不提供挑選、更換、轉讓折換其他等值商品及折現，也不得更換中獎者。獎品日後之使用、保固與維修，主辦單位恕不負責，如獎品有瑕疵、保固與維修等問題，請洽獎品製造商或服務廠商處理。
      * 依所得稅法規定，機會中獎之獎項價值若超過NT$1,000，所得將列入個人年度綜合所得稅申報，若獎品價值總額超過NT$20,000，必須代扣10%中獎獎金稅額；非中華民國國境內居住之國人（即在中華民國境內居住未達183天之本國人及外國人）不論得獎者所得之金額，須就中獎所得扣繳20%機會中獎稅。中獎者須提供身份證件影本、填寫兌獎確認單後方可領獎，並由主辦單位代為申報及寄送扣繳憑單；若不願意配合，視為自動放棄得獎權利。
      * 中獎者若未滿20歲，須獲得法定監護人同意及代為領取。其他未盡事宜，悉依中華民國稅法相關規定辦理。
      * 獎品於中獎名單公告及主辦單位收到中獎者寄回之領獎憑證後，將透過郵寄方式寄送。獎品寄送予中獎者，倘遭拒絕受領(不論實際拒絕受領者為何人)、無人收受、地址錯誤、或查無此人者，或有遺失、冒領、被竊等喪失占有之情形，不論原因為何，亦不論中獎者是否可歸責，主辦單位概不負責補發，並有權視中獎者為棄權，而有權拒絕核發其所中之獎品，中獎者不得異議。
      * 參加者同意，如因步驟不正確或未依照本活動辦法參加抽獎程序等之事由，導致無法參加活動或失去抽獎或贈獎資格之情況，由參加者自行負責。
      * 參加者或得獎者有違反本活動辦法及法令，或以不正當、詐欺或舞弊之行為參加本活動，主辦單位有權取消其抽獎或得獎資格。對於前述行為或其他足以傷及本活動或網站之個人，主辦單位將追究其法律責任。
      * 本活動僅限居住在台澎金馬之中華民國國民參加。
      * 主辦單位及其所屬經銷據點、活動承辦公司之員工（含約聘、臨時雇員），不得參加本活動。中獎者經查為上述人員者將取消中獎資格，並追還所領獎項。
      * 參加者同意接受主辦單位於其官方網站之隱私權保護政策，並同意主辦單位為辦理本活動處理、利用參加者所提供之資料。
      * 參加本活動視為同意接受本活動活動辦法、抽獎辦法所列之各項規範。
      * 本活動如有任何因電腦、網路、技術或其他不可歸責於主辦單位之事由，而使參與本活動者所上傳之資料有遺失、錯誤、無法辨識或毀損所導致資料無效之情況，主辦單位不負任何法律責任，參加者與中獎者亦不得異議。
      * 主辦單位保留變更、修改或終止本活動之權利，並保留對得獎資格之審核及本活動辦法及相關規範之最終決定權利，任何變更均自活動網站公佈時起即時生效；主辦單位並有權就本活動所有事宜進行審查並做出解釋，任何人均不得異議。
      `
          .replace(/\* /g, "")
          .trim()
          .split("\n")
          .map(line => `<li>${line}</li>`)
          .join("") +
        `</ul>` +
        `
        個人資料蒐集聲明與同意事項
        •	裕隆日產依據個人資料保護法規定，明列以下告知事項，敬請參加者詳閱；參加者主動於本活動網站輸入個人資料時，即代表同意裕隆日產依下列條款蒐集、處理、利用所提供之個人資料：
        •	
        1.	蒐集之目的：本活動執行及行銷目的(含中獎聯繫及裕隆日產相關活動訊息之通知)。
        2.	個人資料之類別：真實姓名、手機、E-mail。
        3.	資料利用之期間：參加者同意裕隆日產持續蒐集、處理及利用其個人資料，截至參加者主動請求裕隆日產刪除、停止處理或利用其個人資料為止。惟得獎者所提供中奬收據上所填載之個人基本資料，依據稅法規定裕隆日產最長保存7年，屆時銷毀，不移作他用。
        4.	資料利用地區：台灣、金門、澎湖、馬祖地區。
        5.	資料利用方式：由裕隆日產及活動承辦公司之指定人員利用之，利用人員須依本活動辦法所規範之方式使用此個人資料內容之一部或全部。
        •	參加者得依據個資法第三條規定請求裕隆日產查閱、給予複本、或補正本人的個人資料(可酌收必要成本費用)，亦得隨時洽裕隆日產表示拒絕裕隆日產繼續蒐集、處理、利用或刪除本人的個人資料，惟裕隆日產因執行本活動所必要者，則不在此限。
        •	參加者得自由選擇是否提供相關個人資料，惟若參加者不填寫相關欄位時，將導致參加者喪失參與本活動之抽獎資格。
        •	裕隆日產將依據相關法令之規定建構完善措施，保障參加者個人資料之安全。
        `.split("\n")
          .map(line => `<p>${line}</p>`)
          .join("") 
    };
  },
  methods: {
    updateScroll(){
      this.scrollControl.scrollTo(0,0,0, undefined, true)
    },
    openReserve() {
      console.log("click");
      this.showIframe = true;
    },
    scrollTo(id) {
      if (this.scrollControl) {
        this.scrollControl.scrollTo(document.querySelector(id));
      }
    },
    getSlideImgStyle(song) {
      let backgroundImage = require(`@/static/${song.image}`);
      let style = `
        background-image: url('${backgroundImage}');
      `;
      // console.log(style);
      return style;
    },
    toggleShowModal() {
      this.showModal = !this.showModal;
    },
    openShowSongModal(song) {
      // console.log(song);
      this.song = song;
      this.showSong = true;
    },
    checkLockScroll(val) {
      if (val) {
        this.scrollControl.stop();
      } else {
        this.scrollControl.start();
      }
    },
    openShowVideoModal(vid) {
      // console.log(vid);
      let src;
      if (vid === "vid2") {
        src =
          "https://www.youtube.com/embed/LmCBqQqIqK8?rel=0&playlist=_NUoXioxhHM";
      } else {
        src =
          "https://www.youtube.com/embed/_NUoXioxhHM?rel=0&playlist=LmCBqQqIqK8";
      }
      this.vidSrc = src;
      this.showVideo = !this.showVideo;
    }
  },
  watch: {
    showModal(val) {
      this.checkLockScroll(val);
    },
    showSong(val) {
      this.checkLockScroll(val);
    },
    showVideo(val) {
      this.checkLockScroll(val);
    }
  }
};
</script>

<style lang="sass">
@import "@/assets/css/mixins"

.fullpage
  display: flex
  align-items: center
  justify-content: center
  flex-direction: column
  max-width: 100vw

.justify-start
  .fullpage
    justify-content: flex-start

.slideDetail
  width: 100vw
  height: 100vh
  display: flex
  justify-content: center
  align-items: center
  position: relative
  &.slideVideo
    .fullpage
      +rwd_md
        padding: 0

  .iframe-video
    width: 90%
    height: 100%
    +rwd_md
      width: 100%
      height: 80%
    +rwd_sm
      width: 100%
      height: 80%
  iframe.iframe-form
    width: 50%
    height: 90%
    max-width: 600px
    border: none
    +rwd_md
      width: 80%
    +rwd_sm
      width: 100%
  .img-wrapper
    width: 100%
    height: 100%
    background-size: contain
    background-repeat: no-repeat
    background-position: center
    +rwd_md
      height: 100%
      background-position: 50% 30%
  .slide-img
    width: 100%
    height: 100%
  .slide-content
    position: absolute
    left: 10%
    bottom: 5%
    z-index: 10
    // padding: 0 10px
    +rwd_sm
      bottom: 25%
    .slide-title
      position: relative
      width: fit-content
      font-size: 1.5rem
      color: black
      transform: translateX(0%)
      width: fit-content
      height: auto
      &::before
        content: ''
        width: 120%
        height: 100%
        position: absolute
        background-color: white
        transform: skewX(20deg)
        // top: -15px
        left: -10px
        // border-bottom: 60px solid white
        // border-right:  30px solid transparent
        z-index: -1
      &::after
        content: ''
        width: 80%
        height: 100%
        position: absolute
        background-color: white
        left: -25px
        top: 0
        z-index: -1
.reservation
  text-align: center
  width: 40px
  line-height: 1.5
  padding: 30px 40px 30px 30px
  font-size: 1rem

  position: fixed
  right: -10px
  top: 40%

  z-index: 8
  color: white
  background-image: url('~@/static/reservation-bkg.png')
  background-size: 100% 100%
  background-repeat: no-repeat

  // background-position: 0 100%

  transition: 0.5s
  +rwd_sm
    padding: 25px 35px 25px 25px
    font-size: 0.95rem
    line-height: 1.45
    right: -5px

  &:hover
    cursor: pointer
    right: 0
    transition: 0.3s

.carousel-3d-controls
  a
    span
      color: white !important

.video-player
  // position: fixed
  width: 100vw
  height: 100%
  display: flex
  align-items: center
  justify-content: center
  // left: 0
  // top: 0
  // z-index: 999
  .video-js .vjs-tech
    // width: 100vw !important
    overflow: hidden
      // height: 100vh !important
    background-color: #000 !important
    .vjs-control-bar
      width: 100vw !important
      // height: 100vh !important
  .video-js .vjs-big-play-button
    display: none !important
  .vjs_video_3-dimensions
    max-width: 100vw !important
      // height: 50vh !important
      // max-height: 50vh !important
  .vjs-poster
    display: none !important
  +rwd_sm
    .vjs_video_3-dimensions
      // max-width: 100vw !important
      // height: 50vh !important
      // max-height: 50vh !important
    .vjs-has-started .vjs-control-bar
      // display: none !important
    .vjs-control-bar
      // display: none !important
</style>