<template lang="pug">
div
  transition(name="fade")
    .fullpage(v-if="show")
      slot.front
      .closebg(@click="closeFullpage")
      .cross(@click="closeFullpage")
        i.fas.fa-times
</template>

<script>
export default {
  props: ['show'],
  methods: {
    closeFullpage() {
      this.$emit('closeFullpage');
    },
  },
  mounted() {
    window.addEventListener('keydown', (evt) => {
      // console.log(evt.key)
      if (evt.key === 'Escape') {
        this.closeFullpage();
      }
    });
  },
};
</script>

<style lang="sass">

@import "@/assets/css/mixins"

.fullpage
  position: fixed
  width: 100%
  height: 100vh
  max-height: 100vh
  left: 0
  top: 0

  background-color: rgba(black,0.85)
  z-index: 2000
  overflow-y: auto
  padding: 6vw
  padding-top: 60px
  padding-bottom: 60px
  color: white
  overflow-y: scroll
  +rwd_sm
    padding-top: 30px
  .front
    position: relative
  .closebg
    position: absolute
    z-index: -1
    width: 100%
    height: 100%
    left: 0
    top: 0
  

  .cross
    cursor: pointer
    transform: scale(1.1)
    font-size: 35px
    position: fixed
    right: 3vw
    top: 3vw
    color: white
    transition: 0.5s
    &:hover
      transform: scale(1.2)
</style>
