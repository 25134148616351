<template lang="pug">
section#live.pb-5(@mousemove="mousemoved", ref="section")
  DotBackground
  .container
    .row.mt-5
      .col-sm-12.pt-5.mb-3.live-top
        h1(v-if="!isMobile") 懾人之作 在線發行
        h3(v-else) 懾人之作 在線發行
        p.mt-3 快來觀看NEW JUKE顱內高潮MV線上首發，讓NEW JUKE用ASMR震撼你的感官， <br> 為熱愛駕馭樂趣的你，帶來高潮不斷的演出！
  .container-fluid
    .row.mb-5.d-flex.justify-content-center.align-items-end
      // .col-sm-12.toggle-show-live.mb-3(
        @click="toggleShowLive",
        style="text-align: center; color: white; width: 100%; "
        ) --- 切換直播（MV）/倒數 ---
      .col-md-2
      .col-sm-10.col-md-8.live-video.m-0.p-0
        iframe.iframe-live(
          v-if="isOnAir",
          src="https://www.youtube.com/embed/MTpXlIHIrNc?rel=0",
          frameborder="0",
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
          allowfullscreen
          )
        // iframe.iframe-live(
          v-if="isOnAir",
          src="https://www.youtube.com/embed/HdF2nA6y4mQ",
          frameborder="0",
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
          allowfullscreen
          )
        h2(v-if="!isOnAir") 顱內高潮MV首發會直播
        h3(v-if="!isOnAir") 倒數計時中
        .counter(v-if="!isOnAir")
          .text-countdown(ref="countdown") {{ countDownData.hours }}:{{ countDownData.minutes }}:{{ countDownData.seconds }}
          .text-countdown(style="opacity: 0;", ref="countdownSample") 00
          .text-date(ref="textdate")
            .text-time Hr
            .text-time Min
            .text-time Sec
        img.preview(:src="require('@/static/live-countdown_.png')")
      .col-sm-12.col-md-2
      // .col-sm-12.col-md-2.on-air-box
        a(href="https://reurl.cc/d5og9g",
          target="_blank"
          v-if="isOnAir")
          SvgInline(:src="require('@/static/onair-group.svg')")
    .row
      .col-sm-12.p-0
        .sound-wave-wrapper
          SoundWave(:scaleX="0.3", height="300px")
</template>

<script>
import DotBackground from "./DotBackground";
import SoundWave from "./SoundWave";
import SvgInline from "@/components/SvgInline.vue";
import { mapState } from "vuex";
export default {
  name: "Live",
  data() {
    return {
      isShowLive: false,
      currentTime: new Date().getTime(),
      targetTime: new Date("Nov 19, 2020 15:30:00").getTime()
    };
  },
  props: {},
  methods: {
    toggleShowLive() {
      // this.isShowLive = !this.isShowLive;
      this.$store.dispatch('toggleOnAir')
      if (!this.isOnAir) {
        this.$nextTick(() => {
          this.setDateTextWidth();
        });
      }
    },
    setDateTextWidth() {
      // console.log("update width");
      let textDate = this.$refs.textdate;
      let textTime = document.querySelectorAll(".text-time");
      textTime.forEach(tEl => {
        tEl.style.width = this.$refs.countdownSample.clientWidth + "px";
      });
      textDate.style.width = this.$refs.countdown.clientWidth + "px";
      // + this.$refs.countdown.clientHeight / 2
      // this.$refs.textdate.clientHeight / 2 + 10
      if (window.innerWidth < 750) {
        // console.log(window.innerWidth)
        textDate.style.transform = `translate3d(-50%, -20px, 0)`;
      } else {
        textDate.style.transform = `translate3d(-50%, ${
          this.$refs.countdown.clientHeight / 2
        }px, 0)`;
      }
    },
    mousemoved(evt) {
      var rect = this.$refs.section.getBoundingClientRect();
      var x = evt.clientX - rect.left; //x position within the element.
      var y = evt.clientY - rect.top; //y position within the element.
      window.mousePos = { x, y };
    }
  },
  components: { DotBackground, SoundWave, SvgInline },
  computed: {
    ...mapState(["isMobile", "isOnAir"]),
    countDownData() {
      let timeDiff = this.targetTime - this.currentTime;
      let days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
      let hours = Math.floor(
        (timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      let minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
      let seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);

      hours = hours.toString().length > 1 ? hours : `0${hours}`;
      minutes = minutes.toString().length > 1 ? minutes : `0${minutes}`;
      seconds = seconds.toString().length > 1 ? seconds : `0${seconds}`;

      return {
        days: days,
        hours: hours,
        minutes: minutes,
        seconds: seconds
      };
    }
  },
  mounted() {
    window.addEventListener("resize", this.setDateTextWidth);
    setInterval(() => {
      this.currentTime += 1000;
    }, 1000);
    setTimeout(this.setDateTextWidth, 1000);
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="sass">
@import "@/assets/css/mixins"
#live
  min-height: 100vh
  // background-image: url('~@/static/live-bkg.png')
  background-repeat: no-repeat
  background-position: center
  background-size: cover
  .toggle-show-live
    &:hover
      cursor: pointer
  +rwd_md
    min-height: 80vh
  +rwd_sm
    min-height: 50vh
  .live-top
    color: white
    text-align: center
    p
      font-size: 1.2rem
      line-height: 1.8
      letter-spacing: 0.1em
  .on-air-box
    color: white
    text-align: center
    z-index: 99
    .img-on-air
      width: 100%
      // transform: translateY(20vh)
      // +rwd_xl
      //   transform: translateY(30vh)
      // +rwd_lg
      //   transform: translateY(25vh)
      // +rwd_md
      //   transform: translateY(20vh)
      +rwd_sm
        // transform: translateY(0)
        width: 30vw
        min-width: 250px
  .btn-fb
    &:hover
      text-decoration: none
    h4
      color: white
      text-align: center
      // margin-right: 50px
      position: relative
      width: fit-content
      font-weight: normal
      z-index: 5
      line-height: 2
      +rwd_md
        font-size: 1rem
      &::after
        content: ''
        position: absolute
        top: 0
        left: -5%
        width: 110%
        height: 100%
        // background-color: white
        transform: skewX(-15deg)
        background: -webkit-linear-gradient(120deg, #D561FE 30%, #FF5CAA 70%)
        background: linear-gradient(120deg, #D561FE 30%, #FF5CAA 70%)
        z-index: -1
  .live-video
    display: flex
    // padding: 0 3rem
    align-items: center
    justify-content: center
    flex-direction: column
    position: relative
    .iframe-live
      position: absolute
      top: 0
      left: 0
      width: 100%
      // height: 100%
      height: 100%
      z-index: 10
      // padding: 0 3rem
      +rwd_md
        height: 100%
      +rwd_sm
        padding: 0 1rem
        height: 100%


    h2,h3
      color: white
      text-align: center
      position: absolute
      z-index: 2
    h2
      top: 20%
      font-size: min(5vw, 3rem)
    h3
      top: 33%
    +rwd_lg
      h2
        top: 18%
    +rwd_md
      padding: 0 1rem
      h2
        top: 15%
      h3
        top: 35%
    .text-date
      padding: 0px
      font-weight: 500
      display: flex
      width: 100%
      align-items: center
      justify-content: space-around
      color: white
      position: absolute
      left: 50%
      top: 50%
      z-index: 6
      text-align: center
      transform-origin: 50% 50%
      transform: translate3d(-50%, -50%, 0)
      +rwd_md
        padding: 0px 0px
        font-size: 2.5vw
        top: 65%
      +rwd_sm
        top: 75%
    .text-countdown
      display: inline
      font-size: 5vw
      font-weight: 700
      font-family: 'Noto Sans TC'
      line-height: normal
      z-index: 5
      position: absolute
      left: 50%
      top: 50%
      width: fit-content
      text-align: center
      transform: translate3d(-50%, -50%, 0)
      color: transparent
      background: -webkit-linear-gradient(120deg, #D561FE 30%, #FF5CAA 90%)
      background: linear-gradient(120deg, #D561FE 30%, #FF5CAA 90%)
      background-clip: text
      -webkit-background-clip: text
      text-fill-color: transparent
      -webkit-text-fill-color: transparent
      +rwd_md
        font-size: 10vw
        top: 60%
      +rwd_sm
        top: 60%
    img.preview
      width: 100%
      height: auto
    .preview
      width: 100%
      // height: 100%
      // min-height: 75vh
      // position: absolute
      height: 45vw
      background-repeat: no-repeat
      background-position: 10% auto
      background-size: 120% auto
      filter: brightness(0.3)
      background-image: url('~@/static/live-countdown.png')
      z-index: 1
      +rwd_lg
        height: 45vw
        // min-height: 45vh
      +rwd_md
        min-height: 45vh
        background-size: auto 100%
      +rwd_sm
        min-height: 30vh
        background-position: 0 0
        background-size: auto 100%
  .sound-wave-wrapper
    transform: translateY(-45%)
    position: absolute
    width: 100%
    z-index: 0
    +rwd_sm
      transform: translateY(-50%)
  #onair-group
    animation: wave 2s linear infinite
    &:hover
      #mask_white
        opacity: 1
    #mask_white
      animation: blink 2s linear infinite


@keyframes wave
  0%, 100%
    transform: translateY(-5px)
  50%
    transform: translateY(0)

@keyframes blink
  0%, 100%
    opacity: 1
  50%
    opacity: 0
</style>
