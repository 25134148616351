<template lang="pug">
#main
  .container-fluid
    .row
      .col-12
        .btn-voucher.btn.btn-primary(@click="clickBtn") 領取票券
</template>

<script>
// @ is an alias to /src

export default {
  name: "Main",
  components: {},
  methods: {
    clickBtn(){
      console.log('clicked')
    }
  },
};
</script>

<style lang="sass" scoped>
#main
  .btn-voucher
    &:hover
      cursor: pointer
</style>
