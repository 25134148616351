<template lang="pug">
  .sound-wave(ref="pixi", :style="{height}")
    //- p5-vue-mirror( v-model="p5codes" :hidecode="true")
</template>

<script>
import * as PIXI from 'pixi.js'

import {debounce} from 'lodash'
let gradientMaskContainer
let gradientTexture
let particles = []
function isInViewport(element) {
    const rect = element.getBoundingClientRect();
    return (
        rect.bottom  >= 0 &&
        rect.top<=window.innerHeight
    );
}
export default {
  props: {
    scaleX: {
      default: 1
    },
    height: {
      default: "100%"
    }
  },
  mounted(){
    let appWidth = this.$refs.pixi.clientWidth
    let appHeight = this.$refs.pixi.clientHeight

    // Create the application
    const app = new PIXI.Application({
        transparent: true,
        width: appWidth,
        height: appHeight,
        resizeTo: this.$refs.pixi,
        // backgroundColor: 0x1099bb, 
        resolution: window.devicePixelRatio || 1,
    });

    window.addEventListener('resize', debounce(()=>{
      // console.log("init Bars")
      app.resize()
      this.initBars()
    },500)    )


    this.$refs.pixi.appendChild(app.view);
    app.stage.scale.set(1/window.devicePixelRatio );
    
    gradientMaskContainer = new PIXI.Container()
    gradientTexture = new PIXI.Sprite.from(require('../static/gradient1.png') )
    gradientTexture.mask = gradientMaskContainer
    gradientTexture.x = 0
    gradientTexture.y = 0
    gradientTexture.width = appWidth
    gradientTexture.height = appHeight
    app.stage.addChild(gradientMaskContainer)
    // console.log(gradientTexture)

    app.stage.addChild(gradientTexture)

    this.initBars()

    let ts = 0
    // let dist = (x1,y1,x2,y2) => Math.sqrt( (x2-x1)*(x2-x1)+(y2-y1)*(y2-y1) )
    let map = (v,omin,omax,nmin,nmax, limit=false) =>{
      let result =  (v-omin)/(omax-omin)*(nmax-nmin)+nmin
      if (limit){
        if (nmin>nmax){
          let temp = nmax
          nmax = nmin
          nmin = temp
        }
        return Math.min( Math.max( result,nmin ), nmax)
      }
      return result
    }
    // let lerp = (ov,nv,ratio)=> ov+(nv-ov)*ratio
    // let mouseX=0, mouseY = 0,pmouseX =0, pmouseY=0


    // // Initialize the pixi Graphics class
    // let mouseContainer = new PIXI.Container();
    // let mouseGraphics = new PIXI.Graphics();
    // // mouseGraphics.beginFill(0xFFFF00);
    // mouseGraphics.lineStyle(0.8, 0xffffff,0.4);

    // mouseGraphics.drawCircle(mouseX, mouseY, 40)
    // mouseContainer.addChild(mouseGraphics)
    // app.stage.addChild(mouseContainer)

    // let counter = 0
    app.ticker.add((t)=>{
      if (!isInViewport(app.view)){
        return
      }
      ts+=t
      particles.forEach((p,pId)=>{
        let yScale = Math.sqrt(Math.sin(map(pId,0,particles.length,0,Math.PI)))

        p.graphics.scale.y= yScale*(
          Math.sin( (pId/5+ts/20+ 1)*this.scaleX  ) *2
          +Math.sin( (pId/2.5+ts/7)*this.scaleX  ) *2
          +Math.sin(  (pId/1.5+ts/40+0.2)*this.scaleX  )*3
          +Math.sin(  (pId/3.5+ts/10+0.2)*this.scaleX  )*3
          +Math.sin(  (pId/0.1+ts/100+0.3)*this.scaleX  )*0.5
          +Math.sin((pId/3+ts/150)*this.scaleX  ) *3
        )
      })
    }) 

  },
  methods: {
    initBars(){
      while(gradientMaskContainer.children[0]) { 
          gradientMaskContainer.removeChild(gradientMaskContainer.children[0]);
      }
      let appWidth = this.$refs.pixi.clientWidth
      let appHeight = this.$refs.pixi.clientHeight
      const span = 8
      for(var x=0;x<appWidth;x+=span){
        // for(var y=0;y<appHeight;y+=span){
        let y = appHeight/2
        const rectangle = PIXI.Sprite.from(PIXI.Texture.WHITE);
        rectangle.x = x
        rectangle.y = y
        rectangle.width = 3;
        rectangle.height = appHeight;
        rectangle.anchor.set(0.5);
        // rectangle.alpha = 0.1;
        gradientMaskContainer.addChild(rectangle);

        particles.push(
          {
            x,y,
            graphics: rectangle
          }
        )
        // }
      }
    }
  },
  data(){
    return {

    }
  }
}
</script>
<style lang="sass" scoped>
  .sound-wave
    width: 100%
    height: 200px
    max-width: 100vw
    overflow: hidden
  iframe,.codeArea
    height: 100%
    width: 100% 
    // z-index: 10
    // pointer-events: none
</style>
