<template lang="pug">
  section#discount(@mousemove="mousemoved" ref="section").pb-5
    DotBackground(v-if="!isMobile")
    .container.pt-5.pb-5
      .row
        .col-12.d-flex.align-items-center.justify-content-center.overflow-hidden
          img.discount-banner(:src="require(`@/static/discount-banner.png`)")
   
    .container-fluid.text-center.text-center
      .row(data-scroll  data-scroll-speed="1.5")
        .col-12.discount-content.d-flex.flex-column.align-items-center.justify-content-center
          .discount-title
            img(:src="require(`@/static/discount-title-lg.png`)" v-if="!isMobile")
            img(:src="require(`@/static/discount-title-sm.png`)" v-if="isMobile")
          .discount-desc
            img(:src="require(`@/static/discount-desc.png`)" v-if="!isMobile")
            img(:src="require(`@/static/discount-desc-sm.png`)" v-if="isMobile")
          .discount-cta.mt-3
            img(:src="require(`@/static/discount-cta.png`)")
      .row
        .col-12.mt-5
          img.discount-car(data-scroll data-scroll-speed="-2" data-scroll-direction="horizontal"  
                          :src="require(`@/static/discount-car.png`)")
          .sound-wave-wrapper
            SoundWave(:scaleX="0.3", height="300px")

</template>

<script>
import { mapState } from "vuex";
import DotBackground from './DotBackground'
import SoundWave from './SoundWave'
export default {
  name: "Discount",
  props: {
  },
  components: {
    DotBackground,
    SoundWave
  },
  methods: {
    setLocale() {
      this.$i18n.locale = this.$i18n.locale == "zh" ? "en" : "zh";
    },
    mousemoved(evt){
      var rect =  this.$refs.section.getBoundingClientRect();
      var x = evt.clientX - rect.left; //x position within the element.
      var y = evt.clientY - rect.top;  //y position within the element.
      window.mousePos = {x,y}
    }
  },
  computed:{
    ...mapState(["isMobile"]),
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="sass">
@import "@/assets/css/mixins"

#discount
  // height: 125vh
  min-height: 125vh
  overflow: initial
  +rwd_sm
    .discount-title
      font-size: 1.6rem
  .dot-background
    height: 100%
  &:after
    content: "NEW JUKE"
    font-size: 18rem
    -webkit-text-stroke: 1px white
    color: transparent
    // color: white
    display: block
    position: absolute
    bottom: 9rem
    right: -1rem
    font-weight: 900
    opacity: 0.2
    white-space: nowrap
    z-index: 0
  +rwd_md
    min-height: 100vh
    &:after
      display: none
  .discount-banner
    width: 100%
    +rwd_sm
      width: 200%
    // width: auto
    // height: 20vmin
  .discount-content
    color: white
    img
      display: block
      width: 100%
    .discount-desc
      font-size: 1.2rem
      font-weight: normal
      .discount-desc-title
        font-size: 1.3rem
        +rwd_sm
          font-size: 1rem
        font-weight: bold
      .discount-desc-content
        margin-top: 5px
        margin-bottom: 20px
    .discount-cta
      img
        width: min(60vw, 600px)
        +rwd_md
          width: 80vw
        +rwd_sm
          width: 95vw

  .discount-car
    position: absolute
    left: 0
    top: -5vw
    width: 55vw
    z-index: 1
    +rwd_sm
      width: 85vw
      top: -10vh
  .sound-wave-wrapper
    overflow: hidden
    max-width: 100vw
</style>
