<template lang="pug">
section#seat.pb-5(@mousemove="mousemoved", ref="section")
  DotBackground(v-if="!isMobile")
  .container
    .row.mt-5.mb-5
      .col-12.seat-desc.pt-5.pb-5.mb-5
        .seat-desc-text NISSAN 聯手華納音樂強力打造
          | 「國內首位顱內高潮感官系歌手」，
          | 震撼流行音樂圈！不只有搶眼外型、沉穩內在及性感聲線，還能用聲音由內而外挑動你的感官！11/19 NEW JUKE 顱內高潮 MV 線上首發會開放取票，
          .text-underline 快來選出讓你興奮的位置吧！
  .container
    .row.seat-selection.mt-3
      .col-md-6.col-lg-5.seat-selection-result(data-scroll, data-scroll-speed="-1.5")
        div
          p.pl-1 座位選擇
          h1.seat-title {{ seatDesc[selectedSeat] }}
          .seat-id.mt-3 
            .id-text-lg {{ selectedSeat[0] }}
            .id-text-sm 排
            .id-text-lg {{ selectedSeat[1] }}
            .id-text-sm 號


        .seat-selection-btn.d-flex.flex-column.align-items-center
          a(:href="'http://m.me/nissan.tw?ref=JUKE_'+selectedSeat" target="_blank")
            img.img-btn-send(:src="require(`@/static/seat-btn-send.svg`)")
          // img.img-send-desc(:src="require(`@/static/seat-send-desc.png`)")

      .col-md-6.col-lg-7.d-flex.align-items-center.justify-content-start
        SvgInline.img-seats(
          :src="require('@/static/seats.svg')",
          @updateSvg="bindSeats"
        )
      .col-12.d-block.d-md-none.blur-top
        .seat-selection-btn-mobile.d-flex.flex-column.align-items-center
          a(:href="'http://m.me/nissan.tw?ref=JUKE_'+selectedSeat" target="_blank")
            img.img-btn-send(:src="require(`@/static/seat-btn-send.svg`)")

        .line-deco(data-scroll, data-scroll-speed="-1") {{ selectedSeat }}
  // img.img-seats(:src="require(`@/static/seats.png`)" data-scroll  data-scroll-speed="-2")
  .container
    .row.seat-qualification
      .col-md-6
        p 
          span.underline 抽獎資格
          |
          br
          br
          | 1. 將選位後的電子票券公開分享至個人 FB 塗鴉牆
          br
          | 2. 並於貼文內文加上 #NEWJUKE聲動你的駕馭感官
          br
          | 3. 至FB機器人點選「我同意上述活動辦法」並填寫個資
          br
          br
          | 於活動期間109/11/06 00:00 ～ 109/11/18/ 23:59(以FB系統內時間為準)完成以上步驟，
          | 即可獲得 BOSE 無線消噪耳機抽獎資格，
          | 得獎名單將於 109/11/24(二) 20:00 前公佈於 NISSAN 官方網站及 FB 粉絲團活動貼文留言處
          br
          br
          span.cta(@click="$emit('toggleShowModal')") 
            span.mr-2 詳情請見抽獎辦法
            span.arrow ＞
</template>

<script>
import DotBackground from "./DotBackground";
import SvgInline from "@/components/SvgInline.vue";
import { mapState } from "vuex";
export default {
  name: "SeatSelection",
  data() {
    return {
      selectedSeat: "A1",
      seatDesc: {
        A1: "馭罷不能型顱內高潮",
        A2: "隨人支配型顱內高潮",
        B1: "翻雲覆雨型顱內高潮",
        B2: "眾人擁戴型顱內高潮",
        B3: "想要就要型顱內高潮"
      }
    };
  },
  props: {},
  methods: {
    mousemoved(evt) {
      var rect = this.$refs.section.getBoundingClientRect();
      var x = evt.clientX - rect.left; //x position within the element.
      var y = evt.clientY - rect.top; //y position within the element.
      window.mousePos = { x, y };
    },
    bindSeats() {
      console.log("svg loaded");
      this.$nextTick(() => {
        document.getElementById("bg_A1").classList.add("unselected");
        let seats = document.querySelectorAll("g[id*=seat_]");
        // console.log(seats)
        seats.forEach(seat => {
          seat.addEventListener("click", e => {
            
            document.getElementById("bg_A1").classList.remove("unselected");
            
            // console.log(e.target.id)
            let id = e.target.id.split("_")[1];

            // this.drawLine(id)

            this.selectedSeat = id;
            let seats = document.querySelectorAll("path[id*=bg_]");
            seats.forEach(seat => {
              let parent = seat.parentNode;
              let line = parent.querySelector("[id^=line]");
              // console.log(parent)
              line.classList.remove("seat_selected");
              seat.classList.remove("seat_selected");
            });
            e.target.parentNode
              .querySelector("[id^=line]")
              .classList.add("seat_selected");
            e.target.classList.add("seat_selected");
          });
        });
      });
    },
    drawLine(targetId) {
      console.log(targetId)
      let line = document.getElementById("line")

      if(line){
        line.parentNode.removeChild(line)
      }
      let svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
      let svgPath = document.createElementNS(
        "http://www.w3.org/2000/svg",
        "path"
      );
      let idPos = document.getElementsByClassName("seat-title")[0].getBoundingClientRect()
      let seatPos = document.getElementById(`text_${targetId}`).getBoundingClientRect()
      console.log(idPos);
      console.log(seatPos);
      
      let startX = idPos.width + idPos.left
      let startY = (idPos.top + idPos.height/2)
      let diffX = seatPos.left - idPos.right
      console.log(diffX)

      let pathNr = "";
      pathNr = `M ${startX} ${startY}`
      pathNr += `h 100 0`
      pathNr += `l ${seatPos.left - startX} ${seatPos.top - startY}`
      // pathNr += "h" + seatPos.left;
      // pathNr += "v" + seatPos.top;
      // pathNr += "h" + -seatPos.left;
      svgPath.setAttribute("d", pathNr);
      svgPath.setAttribute("stroke", "red");
      svgPath.setAttribute("stroke-width", "2");
      svgPath.setAttribute("fill", "none");
      svgPath.setAttribute("id", "test");
      svg.setAttribute("id", "line");
      // svg.setAttribute("width", "200");
      // svg.setAttribute("height", "100");
      // svg.setAttribute("viewBox", "200 100");
      svg.appendChild(svgPath);
      console.log(svgPath)


      document.querySelector("#seat").append(svg);

      document.getElementById("line").style.left = 0
      document.getElementById("line").style.top = 0

    }
  },
  components: {
    DotBackground,
    SvgInline
  },
  computed: {
    ...mapState(["isMobile"])
  },
  mounted() {
    this.$modal.show("example");
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="sass">
@import "@/assets/css/mixins"
#seat
  position: relative
  overflow: hidden
  .dot-background
    height: 100%
  .seat-desc
    margin-top: 5vh
    color: white
    font-size: 1.3rem
    +rwd_sm
      font-size: 1rem
      line-height: 1.8
      letter-spacing: 0.1em
    .seat-desc-text
      display: inline-block
    .text-underline
      white-space: pre
      position: relative
      width: fit-content
      text-shadow: 0px 0px 10px rgba(black,0.4)
      display: inline-block
      &:before
        content: ""
        display: block
        position: absolute
        bottom: 0
        left: 0
        width: 100%
        height: 3px
        background-image: linear-gradient(to left, #743ad5, #d53a9d)

    .text-background
      display: inline-block
      color: white
      position: relative
      width: fit-content
      z-index: 0
      margin-left: 5px
      margin-right: 5px
      &:before
        z-index: -1
        content: ''
        width: 100%
        height: 100%
        position: absolute
        left: 0
        top: 0
        background: rgb(92,109,255)
        background: linear-gradient(90deg, rgba(92,109,255,1) 0%, rgba(235,67,188,1) 100%)
        transform: skew(-10deg)
  .seat-selection
    color: white
    position: relative
    .line-deco
      font-size: 50rem
      position: absolute
      // right: 0
      // bottom: 0
      opacity: 0.2
      -webkit-text-stroke: 1px white
      color: transparent
      font-weight: 900
      right: -0.5em
      pointer-events: none
      +rwd_md
        display: none
    .blur-top
      +rwd_md
        z-index: 1

        &:before
          content: ""
          display: block
          position: absolute
          background: linear-gradient(0deg,#080E17 60% , transparent 100%)
          left: 0
          bottom: 0
          width: 100%
          height: 120%
          z-index: -1
          pointer-events: none

    .seat-selection-result
      position: relative
      z-index: 5
      // border: solid 1px transparent
      // &::before
      //   z-index: -1
      //   content: ''
      //   background-image: url('~@/static/seat-deco-circle.svg')
      //   width: 100%
      //   height: 100%
      //   position: absolute
      //   left: 0%
      //   top: 40%
      //   background-repeat: no-repeat
      &::after
        display: none
      +rwd_sm
        &::before
          display: none
        &:after
          content: ""
          display: block
          position: absolute
          background: linear-gradient(180deg,#080E17 60% , transparent 100%)
          left: 0
          top: 0
          width: 100%
          height: 200%
          z-index: -1
          pointer-events: none
      p
        opacity: 1
        border-bottom: solid 1px white
        display: inline-block
    .seat-id
      font-size: 20vmin
      line-height: 0.8
      font-weight: 900
      text-align: right
      display: inline-block
      margin-left: 0.2em
      pointer-events: none
      // margin-left: -2em
      white-space: nowrap

      .id-text-lg, .id-text-sm
        display: inline-block
      .id-text-sm
        font-size: 5vmin
        margin-left: 10px
        margin-right: 5px
        font-weight: normal
      +rwd_md
        font-size: max(15vmin, 5rem)
        .id-text-sm
          font-size: 5vmin
      +rwd_sm
        font-size: max(10vmin, 4rem)
        .id-text-sm
          font-size: 4vmin

    .img-seats
      height: 70vmin
      min-height: 100vh
      width: 25vw
      padding-top: 10vh
      z-index: 10
      +rwd_xl
        min-height: 120vh
      +rwd_md
        width: 100%
        height: 65vmin
        min-height: 80vh
        svg
          width: 100%
          margin-left: 0%
          transform: translate3d(-10%, -30%,0)
          &:before
            content: ""
            display: block
            position: absolute
            background: linear-gradient(180deg,#080E17 40% , transparent 100%)
            background-position: center
            left: 0
            top: 0
            width: 100%
            height: 35%
      +rwd_sm
        height: 70vmin
        min-height: 80vh
        z-index: 0
      +rwd_xs
        height: 60vmin
        min-height: 70vh
    .seat-selection-btn
      transform: translateX(10%)
      transition-duration: 1s
      img
        width: fit-content
        max-width: 300px
      .img-send-desc
        margin-top: -40px
      +rwd_sm
        display: none !important
      &:hover
        transform: translateX(10%) scale(1.2)
        transition-duration: 0.5s
  .seat-qualification
    position: relative
    z-index: 5
    color: white
    margin-top: -10vh
    +rwd_xl
      margin-top: -55vh
    +rwd_lg
      margin-top: -50vh
    +rwd_md
      margin-top: -10vh
    +rwd_sm
      margin-top: 5vh
    .underline
      padding: 5px
      border-bottom: solid 1px white

  .cta
    opacity: 1
    cursor: pointer
    transition: 0.5s
    position: relative
    z-index: 50
    .arrow
      display: inline-block
      transition: 0.5s
      transform: translateX(0px)
    &:hover
      opacity: 1
      .arrow
        transform: translateX(10px)

path[id^='bg_'].seat_selected
        fill: url(#gradient)
        fill-opacity: 0.8
        filter: url("#blur")

path[id^='line_']
        stroke-width: 0px

path[id^='line_'].seat_selected
        stroke-width: 3px

path[id^='bg_A1'].unselected
        animation: gradientLight infinite 4s
        fill: url(#gradient)
        fill-opacity: 0.8
        filter: url("#blur")

@keyframes gradientLight
  0%, 100%
    fill: url(#gradient)
    fill-opacity: 1
    filter: url("#blur")
  50%
    fill-opacity: 0.3

#seat
  .seat-selection
    svg
      +rwd_sm
        path[id^='line_'].seat_selected
          stroke-width: 0px

path[id^='text_']
        pointer-events: none

g[id^='seat_']:hover
  // pointer-events: none
        cursor: pointer
  path[id*="bg"]
    // fill: none
        fill: url(#gradient)
        filter: url("#blur")
  [id*="outline"]
    stroke: url(#gradient)
    stroke-width: 2
    // filter: url("#blur2")

.main-stop
  stop-color: #A661FE
.alt-stop
  stop-color: #FF5CAA

svg#line
  position: absolute
  left: 0
  top: 0
  width: 100%
  height: 100%
  z-index: 999
  pointer-events: none

#line_A1:before
  content: ''
  width: 100px
  height: 100px
  background-color: white
  position: absolute
  top: 0
  left: 0
  z-index: 99999
</style>
