<template lang="pug">
section#nav.container-fluid 
  .row
    .links(:class="{ active, hasBackground }")
      .mobile-header.p-3
        a(href="https://new.nissan.com.tw/nissan/?utm_source=2020_new_juke" target="_blank")
          img(
            :src="require(`@/static/nissan-logo-text.svg`)",
          )
          // @click="$emit('scrollTo', '#intro')"
        .hambergur(:class="{ active }", @click="active = !active")
      // a.link(@click="$store.dispatch('toggleDisplayLive'); $emit('updateScroll')") {{$store.state.displayLive?'隱藏':'顯示'}}倒數頁面
      a.link(
        v-for="link in links",
        @click="$emit('scrollTo', link.url); active = !active",
        v-if="active || !isMobile"
      ) {{ link.name }}
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "Nav",
  props: {},
  mounted() {},
  methods: {},
  computed: {
    ...mapState(["isMobile", "scrollY"]),
    hasBackground() {
      return !this.isMobile && this.scrollY > 1000;
    }
  },
  data() {
    return {
      active: false,
      links: [
        // {
        //   name: "搶票專區",
        //   url: "#seat"
        // },
        {
          name: "直播首發",
          url: "#live"
        },
        {
          name: "聲動展演",
          url: "#teaser"
        },
        {
          name: '歌手亮相',
          url: '#playlist'
        },
        {
          name: '預售優惠',
          url: '#discount'
        },
      ]
    };
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="sass">
@import "@/assets/css/mixins"

#nav
  position: fixed
  z-index: 56
  a.link
    text-decoration: none
    color: #eee
    border-bottom: solid 3px transparent
    border-image-slice: 1
    position: relative
    display: block
    overflow: hidden
    cursor: pointer
    transition: 0.5s
    text-shadow: 0px 0px 10px rgba(black,0.4)
    &:before
      content: ""
      display: block
      position: absolute
      bottom: 0
      left: 0
      width: 100%
      height: 3px
      cursor: pointer
      background-image: linear-gradient(to left, #743ad5, #d53a9d)
      transform: translateX(-100%)
      transition: 0.5s
    &:hover
      color: rgba(white,1)
      &:before
        transform: translateX(0px)
  .links
    color: white
    font-size: 18px
    width: 100%
    display: flex
    flex-direction: row
    align-items: center
    justify-content: flex-end
    // padding-top: 36px
    padding-top: 18px
    padding-bottom: 18px
    transition: 0.5s
    background-color: transparent

    &.hasBackground
      background-color: black

    .link
      margin-right: 48px

  .mobile-header
    display: none

  +rwd_sm
    .mobile-header
      // display: block
      width: 100%
      display: flex
      justify-content: space-between
      align-items: center
      .hambergur
        position: relative
        margin-right: 5px
        cursor: pointer
        background-color: transparent
        width: 30px
        height: 20px

        &:before,&:after
          content: ""
          display: block
          height: 4px
          width: 35px
          background-color: #fff
          position: absolute
          left: 50%
          top: 50%
          transform: translate(-50%,-50%)
          transform-origin: center center
          transition: 0.5s
        &:before
          transform: translate(-50%,-8px)
        &:after
          transform: translate(-50%,8px)
        &.active
          &:before
            transform: translate(-50%,-50%) rotate(45deg)
          &:after
            transform: translate(-50%,-50%) rotate(-45deg)
    .links
      // display: none
      display: flex
      position: fixed
      background-color: black
      width: 100%
      justify-content: center
      align-items: center
      flex-direction: column
      padding: 0
      height: auto
      overflow: hidden
      // max-height: 200px
      transition: 0.5s
      &.active
        max-height: 400px

      .link
        display: block
        margin: 0
        width: 100%
        flex: 1
        padding: 10px
        border-top: solid 1px rgba(white,0.4)
        text-align: center
</style>
