import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    browser: {
      name: "",
      version: ""
    },
    isMobile: false,
    scrollY: 0,
    displayLive: true,
    isOnAir: false,
    showMV: false
  },
  mutations: {
    setBrowser(state, data) {
      state.browser = data
    },
    setIsMobile(state, data){
      state.isMobile=data
    },
    setScrollY(state,data){
      state.scrollY = data
    },
    setLivePage(state){
      state.displayLive = !state.displayLive
    },
    setOnAir(state){
      state.isOnAir = true
    }
  },
  actions: {
    toggleDisplayLive({ commit }){
      commit('setLivePage')
    },
    toggleOnAir({ commit }){
      commit('setOnAir')
    }
  },
  modules: {}
});
