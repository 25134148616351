<template lang="pug">
  #ticket
    .form
      .form-check.form-check-inline
        input#inlineCheckbox1.form-check-input(type="radio" value="A1" name="inlineRadioOptions" v-model="selectedSeat")
        label.form-check-label(for="inlineCheckbox1") A1
      .form-check.form-check-inline
        input#inlineCheckbox2.form-check-input(type="radio" value="A2" name="inlineRadioOptions" v-model="selectedSeat")
        label.form-check-label(for="inlineCheckbox2") A2
      .form-check.form-check-inline
        input#inlineCheckbox3.form-check-input(type="radio" value="B1" name="inlineRadioOptions" v-model="selectedSeat")
        label.form-check-label(for="inlineCheckbox3") B1
      .form-check.form-check-inline
        input#inlineCheckbox4.form-check-input(type="radio" value="B2" name="inlineRadioOptions" v-model="selectedSeat")
        label.form-check-label(for="inlineCheckbox4") B2
      .form-check.form-check-inline
        input#inlineCheckbox4.form-check-input(type="radio" value="B3" name="inlineRadioOptions" v-model="selectedSeat")
        label.form-check-label(for="inlineCheckbox4") B3
    br
    h5 您選擇的是： {{ selectedSeat }} 位置
    br
    .btn.btn-primary(@click="openMessenger") 立刻取票
    br
    br
    .text(style="display: inline-block;") 從分享連結進入機器人（從頭開始選擇座位）：
    br
    a.link(href="http://m.me/nissan.tw?ref=JUKE_FROM_URL" target="_blank") http://m.me/nissan.tw?ref=JUKE_FROM_URL
    
</template>




<script>
// @ is an alias to /src

export default {
  name: "Ticket",
  components: {
  },
  data() {
    return {
      selectedSeat: "A1"
    }
  },
  methods: {
    openMessenger(){
      console.log('open messenger')
      // http://m.me/Jedisapprentice?ref=JUKE_${this.selectedSeat}
      // http://m.me/nissan.tw?ref=JUKE_FROM_URL
      let messengerLink = `http://m.me/nissan.tw?ref=JUKE_${this.selectedSeat}`
      console.log(messengerLink)
      window.open(messengerLink)
    }
  },
};
</script>
