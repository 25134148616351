<template lang="pug">
  .dot-background.position-absolute(ref="pixi" )
    //- p5-vue-mirror( v-model="p5codes" :hidecode="true")
</template>

<script>
import * as PIXI from 'pixi.js'
import {debounce} from 'lodash'
let particles = []
let dotContainer

function isInViewport(element) {
    const rect = element.getBoundingClientRect();
    return (
        rect.bottom  >= 0 &&
        rect.top<=window.innerHeight
    );
}
export default {
  mounted(){

    window.addEventListener('resize', debounce(()=>{
      // console.log("init Dots")
      this.initDots()
    },500)    )

    let appWidth = this.$refs.pixi.clientWidth
    let appHeight = this.$refs.pixi.clientHeight
    
    // Create the application
    const app = new PIXI.Application({
        transparent: true,
        width: appWidth,
        height: appHeight,
        resizeTo: this.$refs.pixi,
        // backgroundColor: 0x1099bb, 
        resolution: window.devicePixelRatio || 1,
    });
    this.app = app
    this.$refs.pixi.appendChild(app.view);
    
    app.stage.scale.set(1/window.devicePixelRatio );
    
    this.initDots()

    let ts = 0
    let dist = (x1,y1,x2,y2) => Math.sqrt( (x2-x1)*(x2-x1)+(y2-y1)*(y2-y1) )
    let map = (v,omin,omax,nmin,nmax, limit=false) =>{
      let result =  (v-omin)/(omax-omin)*(nmax-nmin)+nmin
      if (limit){
        if (nmin>nmax){
          let temp = nmax
          nmax = nmin
          nmin = temp
        }
        return Math.min( Math.max( result,nmin ), nmax)
      }
      return result
    }
    let lerp = (ov,nv,ratio)=> ov+(nv-ov)*ratio
    let mouseX=0, mouseY = 0,pmouseX =0, pmouseY=0


    // Initialize the pixi Graphics class
    let mouseContainer = new PIXI.Container();
    let mouseGraphics = new PIXI.Graphics();
    // mouseGraphics.beginFill(0xFFFF00);
    mouseGraphics.lineStyle(0.8, 0xffffff,0.4);

    mouseGraphics.drawCircle(mouseX, mouseY, 40)
    mouseContainer.addChild(mouseGraphics)
    app.stage.addChild(mouseContainer)

    let counter = 0
    app.ticker.add((t)=>{
      if (!isInViewport(app.view)){
        return
      }
      ts+=t
      if (window.mousePos){
        let {x,y} = window.mousePos
        if ( Math.abs(x-mouseX)>800 || Math.abs(y-mouseY)>800 ){
          mouseX=x
          mouseY=y
          counter=0.01
        }else{
          mouseX = lerp(mouseX,x,0.1)
          mouseY = lerp(mouseY,y,0.1)
        }
        pmouseX = mouseX
        pmouseY = mouseY
      } 

      if (Math.abs(mouseX-pmouseX)>5 ){
        counter/=1.2
      }
      counter++

      let circleSz = 2-2/Math.sqrt(counter)
      mouseContainer.x = mouseX
      mouseContainer.y = mouseY
      mouseContainer.scale.set(circleSz)
      
      particles.forEach( (p,pid)=>{
        let alpha =40;
        let dd = dist(mouseX,mouseY,p.x,p.y)
        let sz= map(dd,0,300,5,2,true)
        // alpha += map(dd,0,300,255,0,true)
        alpha += map(Math.sin(dd/50- ts/20 + mouseX/1000+mouseY/1000),-1,1,200,0,true) *
                 map(dd,0,300,1,0)


        p.graphics.alpha = alpha/255
      })
    })

  },
  data(){
    return {

    }
  },
  methods: {
    initDots(){
      let app = this.app
      let appWidth = this.$refs.pixi.clientWidth
      let appHeight = this.$refs.pixi.clientHeight
      if (this.dotContainer){

        while(this.dotContainer.children[0]) { 
            this.dotContainer.removeChild(this.dotContainer.children[0]);
        }
        app.stage.removeChild(dotContainer)
        particles.length=0
      }
      dotContainer = new PIXI.Container()
      const span = 32
      app.stage.addChild(dotContainer);
      for(var x=0;x<appWidth;x+=span){
        for(var y=0;y<appHeight;y+=span){

          const rectangle = PIXI.Sprite.from(PIXI.Texture.WHITE);
          rectangle.x = x
          rectangle.y = y
          rectangle.width = 3;
          rectangle.height = 3;
          rectangle.alpha = 0.1;
          dotContainer.addChild(rectangle)

          particles.push(
            {
              x,y,
              graphics: rectangle
            }
          )
        }
      }
    }
  }
}
</script>
<style lang="sass" scoped>
  .dot-background
    width: 100%
    height: 100%
    max-width: 100%
    position: absolute
    left: 0
    top: 0
    pointer-events: none
  iframe,.codeArea
    height: 100%
    width: 100% 
    // z-index: 10
    // pointer-events: none
</style>
