import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import "core-js"
import "regenerator-runtime/runtime"
import "bootstrap"; // Import js file
import "@/assets/css/style.scss";
import axios from "axios";
import VueI18n from 'vue-i18n'
import langs from './assets/langs'
import VueGtag from "vue-gtag";
import Bowser from "bowser"
import "./assets/js/preload"
import "@fortawesome/fontawesome-free/js/all.js"
import "animate.css"

import VModal from 'vue-js-modal/dist/index.nocss.js'
Vue.use(VModal)

// console.log(process.env.VUE_APP_DOMAIN)
window.domain = process.env.VUE_APP_DOMAIN
// const VueScrollTo = require('vue-scrollto');

// Vue.use(VueScrollTo)


// global var settings
window.router = router

// Set up axion
window.axios = axios;
window.Vue = Vue;

// Set up browser
const browser = Bowser.getParser(window.navigator.userAgent);
store.commit("setBrowser", browser.getBrowser())

// Set up i18n
const locale = localStorage.getItem('lang') || 'zh'
Vue.use(VueI18n);
const i18n = new VueI18n({
  locale,
  messages: langs
})

let checkScreenSize = () => {
  store.commit("setIsMobile", window.innerWidth < 800)
}
checkScreenSize()
window.addEventListener("resize", checkScreenSize)

const liveTs = Date.parse('19 Nov 2020 15:00:00 GMT+0800')
// const liveTs = Date.parse('19 Nov 2020 12:39:00 GMT+0800')
const liveDateTime = new Date(liveTs)
let checkLiveStatus = function () {
  let switchState = new Date() >= liveDateTime
  if (switchState &&!store.state.isOnAir) {
    store.dispatch('toggleOnAir')
  }
}
setInterval(checkLiveStatus, 1000)

// Set up Google Analytics
// const GAID = process.env.NODE_ENV === 'development' ? 'UA-113459032-1' : process.env.VUE_APP_GAID;

Vue.use(VueGtag, {
  config: {
    id: 'UA-62451074-6'
  },
  includes: [{
    id: 'G-02EVZ54DVT' //multiple trackers
  }]
}, router);

Vue.config.productionTip = false;

new Vue({
  i18n,
  router,
  store,
  render: h => h(App),
  mounted() {
    // build prerendered site
    document.dispatchEvent(new Event('render-event'));
  }
}).$mount("#app");