<template lang="pug">
section#footer.container-fluid.mt-5
  .row
    .col-sm-1(v-if="!isMobile")
    .col-sm-10(v-if="!isMobile")
      .footer-inner
        .links
          a(target="_blank", href="https://new.nissan.com.tw/nissan/?utm_source=2020_new_juke") NISSAN &#x5B98;&#x65B9;&#x7DB2;&#x7AD9;
          a(target="_blank", href="https://www.facebook.com/nissan.tw/?utm_source=2020_new_juke")
            img.icon(:src="require(`@/static/footer-icon-fb.png`)")
            span.txt NISSAN &#x53F0;&#x7063;&#x7C89;&#x7D72;&#x5718;
          a(target="_blank", href="https://www.youtube.com/user/NISSANTAIWAN/?utm_source=2020_new_juke")
            img.icon(:src="require(`@/static/footer-icon-youtube.png`)")
            span.txt &#x5B98;&#x65B9;YouTube &#x983B;&#x9053;
          a(target="_blank", href="https://line.me/R/ti/p/@nissan.tw/?utm_source=2020_new_juke")
            img.icon(:src="require(`@/static/footer-icon-line.png`)")
            span.txt &#x5B98;&#x65B9; LINE &#x5E33;&#x865F;
          a(target="_blank", href="https://www.instagram.com/nissantw/?utm_source=2020_new_juke")
            img.icon(:src="require(`@/static/footer-icon-ig.png`)")
            span.txt NISSAN IG &#x7C89;&#x7D72;&#x5718;
    .col-sm-2(v-if="isMobile")
    .col-sm-8(v-if="isMobile")
      .footer-inner.footer-mobile
        a(target="_blank", href="https://new.nissan.com.tw/nissan/?utm_source=2020_new_juke") NISSAN &#x5B98;&#x65B9;&#x7DB2;&#x7AD9;
        .links
          a(target="_blank", href="https://www.facebook.com/nissan.tw/?utm_source=2020_new_juke")
            img.icon(:src="require(`@/static/footer-icon-fb.png`)")
            // span.txt NISSAN &#x53F0;&#x7063;&#x7C89;&#x7D72;&#x5718;
          a(target="_blank", href="https://www.youtube.com/user/NISSANTAIWAN/?utm_source=2020_new_juke")
            img.icon(:src="require(`@/static/footer-icon-youtube.png`)")
            // span.txt &#x5B98;&#x65B9;YouTube &#x983B;&#x9053;
          a(target="_blank", href="https://line.me/R/ti/p/@nissan.tw/?utm_source=2020_new_juke")
            img.icon(:src="require(`@/static/footer-icon-line.png`)")
            // span.txt &#x5B98;&#x65B9; LINE &#x5E33;&#x865F;
          a(target="_blank", href="https://www.instagram.com/nissantw/?utm_source=2020_new_juke")
            img.icon(:src="require(`@/static/footer-icon-ig.png`)")
            // span.txt NISSAN IG &#x7C89;&#x7D72;&#x5718;
    .col-sm-12.mb-5
      a
        .declare(href="https://new.nissan.com.tw/nissan/?utm_source=2020_new_juke" target="_blank") 2020 &copy; NISSAN TAIWAN.
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "Footer",
  props: {
    msg: String
  },
  methods: {
    setLocale() {
      this.$i18n.locale = this.$i18n.locale == "zh" ? "en" : "zh";
    }
  },
  computed: {
    ...mapState(["isMobile"])
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
#footer a {
  text-decoration: none;
  color: white;
}

#footer{
  .footer-inner{
    display: flex;
    align-items: center;
    justify-content: center;
    .links{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
    }
  }
}

#footer .footer-inner .links a .icon,
#footer .footer-inner .links a .icon {
  display: inline-block;
  margin-right: 10px;
  margin-left: 10px;
  height: 20px;
  width: auto;
}

#footer .declare {
  width: 100%;
  text-align: center !important;
  color: white;
  font-size: 13px;
}

.footer-mobile {
  width: 100%;
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>
