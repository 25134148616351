<template lang="pug">
section#teaser
  .container-fluid
    .row.teaser-vid
      .col-lg-6.p-0
        .teaser-vid-left.teaser-vid-part
          .bkg
            .img(data-scroll, data-scroll-speed="1")
              .img-bkg
            .overlay
          .title(data-scroll, data-scroll-speed="2")
            .title-icon(@click="$emit('openShowVideoModal', 'vid2')")
              img.img-title-icon(
                :src="require(`@/static/icon_play_center.png`)"
              )
              img.img-title-icon(
                :src="require(`@/static/icon_play_border.png`)"
              )
            .title-text
              h1 【外型篇】
              h2 有型 才敢外放
      .col-lg-6.p-0
        .teaser-vid-right.teaser-vid-part
          .bkg
            .img(data-scroll, data-scroll-speed="-1")
              .img-bkg
            .overlay
          .title(data-scroll, data-scroll-speed="2")
            .title-icon(@click="$emit('openShowVideoModal', 'vid1')")
              img.img-title-icon(
                :src="require(`@/static/icon_play_center.png`)"
              )
              img.img-title-icon(
                :src="require(`@/static/icon_play_border.png`)"
              )
            .title-text 
              h1 【內裝篇】
              h2 從細節啟發感官
</template>

<script>
export default {
  name: "Teaser",
  props: {
    msg: String
  },
  data() {
    return {

    };
  },
  methods: {

  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="sass">
@import "@/assets/css/mixins"
#teaser
  min-height: 80vh
  // height: 80vh
  width: 100vw
  max-width: 100vw
  // border: 1px solid black
  overflow: hidden
  .teaser-vid
    min-height: 80vh
    .teaser-vid-part
      padding: 0
      display: block
      position: absolute
      width: 100%
      height: 100%
      cursor: pointer
      transition: .3s ease
      .bkg
        position: absolute
        left: -28%
        top: 0
        width: 128%
        height: 100%
        overflow: hidden
        transform: skewX(-10deg)
        transform-origin: 0% 100%
        .img
          position: absolute
          left: 0%
          top: 0
          width: 128%
          height: 100%
          .img-bkg
            background-size: cover
            background-position: 10% 50%
            transform: skewX(10deg)
            transform-origin: 0% 100%
            position: absolute
            top: 0
            left: 0
            width: 100%
            height: 100%
            transition: .3s ease
      +rwd_md
        img
          .img-bkg
            background-position: 50% 50%
            background-size: cover
      .title
        position: absolute
        display: flex
        width: 100%
        align-items: center
        // justify-content: flex-start
        // width: fit-content
        .title-text
          color: white
          text-align: center
          text-shadow: 0px 0px 20px rgba(black,0.8)
          h1
            font-size: 3rem
            +rwd_sm
              font-size: 2rem
          h2
            font-size: 1.5rem
            +rwd_sm
              font-size: 1rem
        .title-icon
          transition: 0.8s
          width: 100px
          height: 100px
          position: relative
          &:hover
            transform: scale(1.1)
            transition: 0.5s
            img:last-child
              animation: rotate 5s infinite linear
              // +rwd_md
              //   transform: scale(0.6)

          img
            position: absolute
            width: 100px
            height: 100px
            &:first-child
              width: 80px
              height: 80px
              left: 10px
              top: 10px
            +rwd_md
              transform: scale(0.8)
              transform-origin: 50% 50%

    .teaser-vid-left
      top: 0
      left: 0
      .bkg
        .img
          .img-bkg
            background-image: url('~@/static/highlight-1.jpg')
            background-size: cover
      .title
        left: 0%
        top: 50%
        padding-bottom: 50px
        justify-content: center
        .title-icon
          margin-left: 20px

    .teaser-vid-right
      top: 0
      left: 8vw
      +rwd_sm
        left: 60px
      .bkg
        .img
          left: -20%
          .img-bkg
            background-image: url('~@/static/teaser-right.jpg')
            background-size: cover
      .title
        left: 0%
        top: 50%
        padding-bottom: 50px
        justify-content: flex-start
        +rwd_md
          justify-content: center
          left: -15%

@keyframes rotate
  0%, 100%
    transform: rotate(0deg)
  50%
    transform: rotate(360deg)
</style>
